import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Carousel from "../components/carousel"
import ImageRow from "../components/image-row"
import Break from "../components/break"
import Titles from "../components/titles"
import SEO from "../components/seo"
import ContactForm from "../components/contact-form"

const images = [{url:"/angela-goh-1.jpg", details:<div><div className="title">Angeline Goh</div><div className="materials">Being Different</div><div className="dimensions"><span>Wood, epoxy, clay figurines</span><span>125 x 31 x 31 cm</span>
<span>Edition of 10</span></div></div>},{url:"/angela-goh-3.jpg", details:<div><div className="title">Angeline Goh</div><div className="materials">Play Ball </div><div className="dimensions"><span>Clay</span><span>30 x 22 x 13 cm</span>
</div></div>}]
const images_highlight = [
  {url:"/isabel-4.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Flight II</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/isabel-5.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Creation of Man</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/isabel-6.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Metamorphosis</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m</span>
</div></div>
}]
const images_highlight2 = [
  {url:"/isabel-7.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Flight II</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/isabel-1.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Creation of Man</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/isabel-2.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Metamorphosis</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m</span>
</div></div>
}]

const IndexPage = () => (<div>
  <Layout>
    <SEO title="Services" />
    <div style={{ }}>
      
      
      

      
      
      <h1 style={{marginBottom:"60px", fontSize:"36px",marginTop:"80px", textAlign:"center"}}>Shipping Policy</h1>
      <ImageRow rowType={"largeRow"} images={[{type:"text", text: <div >
    <p style={{padding:"40px"}}>
      <b>CONDITIONS OF SALE</b><br /><br />
All artworks purchased online will be prepared and shipped once full payment for both the artwork and shipping has been received.<br />  
Each artwork will be packed specifically to suit its individual needs.<br />
The size, weight, particular packing requirements and the delivery destination all determine the final cost of packing and delivery.<br />
Once payment has been received and the artwork dispatched, Frances Keevil will notify you with the delivery details and tracking number <br />
<br /><br />
<b>DELIVERY</b><br /><br />
Frances Keevil uses Australia Post, specialist artwork transporters and courier services. You will be responsible for the cost of shipping and/or handling and please be aware that you will be liable and responsible for any import requirements or restrictions, licences, duties, taxes and/or government charges that may apply.
Delivery times for artworks is generally between 1 – 3 weeks for Australian Metro areas. Delivery to regional areas may take longer. International deliveries will be organised on an individual basis. For further enquiries contact frances@franceskeevil.com.au

        
    </p>  </div>, span:"12"}] } />
      </div>


  </Layout>

</div>
)

export default IndexPage
